import { Component, OnInit ,Output,EventEmitter } from '@angular/core';
import { DHQServiceService } from '../services/DHQ-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import { DHQ,DHQ1 } from '../Model/DHQModel';
import { DistrictModel } from '../Model/DistrictModel';
import { TehsilModel } from '../Model/TehsilModel';
import { UpdateDHQModel } from '../Model/DHQDetailModel';
import { ExportService} from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { forEach } from '@angular/router/src/utils/collection';
declare var $: any;

@Component({
  selector: 'app-DHQ',
  templateUrl: './DHQ.component.html',
  styleUrls: ['./DHQ.component.css']
})
export class DHQComponent implements OnInit {

  DHQList : Array<DHQ>= new Array();
  DHQUserPatientsList : Array<DHQ>= new Array();
  districtModel : Array<DistrictModel> = new Array();
  teshilModel : Array<TehsilModel> = new Array();
  isAdmin : boolean = false;
  public DHQName : string;
  p:boolean=null
  public DHQContact : string;
  public DHQAddress : string;
  public DHQDistrict : string;
  public Locality1: number;
  selectedDistrict : number ;
  showPatient: boolean= false;
  updateDHQModel : UpdateDHQModel = new UpdateDHQModel();
  @Output() headerTitle = new EventEmitter<string>();
  selectedDHQ: DHQ = new DHQ();
  DHQUserPatientsListbyref:any;
  selectedDHQDATA: DHQ1 = new DHQ1();
  DHQUser: DHQ = new DHQ();
  selectedDHQDetails: boolean= false;
  showPatientDetail:boolean= false;
  showDhqdata:boolean= false;
  dp:boolean=true
  AppointmentsDetails: Array<any>= new Array();
  apdate:boolean=false
  districtdefModel: Array<any>= new Array();
  constructor(private DHQService: DHQServiceService, private genericService: GenericServiceService, private router: Router,private exportService: ExportService,private Toastr: ToastrService) { }

  ngOnInit() {
    this.selectedDHQDetails = false;
    this.headerTitle.emit("DHQ");
    // Get District
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='RHC'  || localStorage.getItem("role")=='SuperAdmin'){
      this.isAdmin = true;
      this.districtModel = [];
      this.genericService.getAllDistrictData().subscribe(data=>{
        this.districtdefModel = data;
        var ActiveDistrictModel = [];
        this.districtdefModel.forEach(function (value) {
        if(value.IsActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.districtModel=ActiveDistrictModel
    
  
     );    
      },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
      this.DHQList = [];
      this.DHQService.getAllDHQ().subscribe(data=>{
      this.DHQList = data;
      },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    }    
  }

  createDHQ(){

    // console.log(this.DHQName,this.DHQContact,this.DHQAddress,this.Locality1);
    this.DHQService.CreateNewDHQ(this.DHQName,this.DHQContact,this.DHQAddress,this.Locality1).subscribe((resp)=>{
      // Store access token, UserId and Role in local storage
      this.Toastr.success('DHQ Created Successfully ');
      $("#createDHQModal").modal("hide");
      this.ngOnInit();
      this.DHQName='';
      this.DHQContact='';
      this.DHQAddress='';
      },
      (err)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }


  // code added for user creation for covid department for all district 'this.DHQName,this.DHQContact,this.DHQAddress,this.Locality1
  CreateCovidDHQUsers(){
    this.DHQService.CreateCovidDHQUser().subscribe((resp)=>{
      this.Toastr.success('DHQ Updated Successfully ');
      // $("#createDHQModal").modal("hide");
      this.ngOnInit();
      },
      (err)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }

  selectDistricForDHQ(districtId: any){
    this.Locality1 = parseInt(districtId);
  }

  showSelectedDistrictDHQ (districtId:any){
    this.DHQList = [];
    this.DHQService.getSelectedDistrictDHQ(districtId).subscribe(data=>{
      this.DHQList = data; 
      // this.DHQList = this.DHQList.slice(0,-2); 
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
  }

  selectedLab(lab : DHQ){
    this.selectedDHQ = lab;
    this.selectedDHQDetails = true;
    this.dp=false
    // console.log(lab.DHQUsers[4].Name)
    
  }
  SelectedReferral(data){
    this.AppointmentsDetails = data.Appointments;
this.apdate=true
  }
  editselectedDHQUser(DHQuser: DHQ){
    this.DHQUser = DHQuser;
    // console.log(this.DHQUser)
  }

  SelectedDhqdata(dhqdata :DHQ1)
  {
    this.selectedDHQDATA = dhqdata;
    this.showDhqdata = true
    this.selectedDHQDetails=false
    // console.log("dhq data",this.selectedDHQDATA);
    
    
  }
  refreshddata(){
    $('#dataofslected').on('hidden.bs.modal',
    function () {
     $('#dataofslected form')[0].reset();
     });
  }
   
  selectedDHQUserPatients(DHQUserPatients){
    this.showPatientDetail = true;
    // this.DHQUserPatientsList = [];
    this.DHQService.getSelectedDHQUserPatients(DHQUserPatients.DHQLoginId).subscribe(data=>{
      this.DHQUserPatientsList = data; 
       
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
  }

  selectDistricForUpdatingDHQ(tehsilId: any){
    this.Locality1 =  parseInt(tehsilId);
    // console.log(this.Locality1)
  }

  EditLab(){
    $("#editLabModal").modal("hide");
    // console.log("Before: "+this.selectedDHQ.DHQId);
    this.updateDHQModel.DHQId = this.selectedDHQ.DHQId;
    this.updateDHQModel.Name = this.selectedDHQ.DHQName;
    this.updateDHQModel.Address = this.selectedDHQ.Address;
    this.updateDHQModel.Contact = this.selectedDHQ.Contact;
    this.updateDHQModel.DistrictId = this.selectedDHQ.DistrictId;
    this.updateDHQModel.IsActive = this.selectedDHQ.IsActive;

    // console.log(this.updateDHQModel);
    this.DHQService.UpdateDHQ(this.updateDHQModel).subscribe(resp=>{
      this.Toastr.success('DHQ Updated Successfully ');
      this.ngOnInit();
      // console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  } 

  checkboxClicked() {
    this.DHQUser.ScheduledDays.filter(x => x.IsScheduled).map(x => {
      // console.log(x.ScheduledDayId);

    //  this.DHQUser.ScheduledDays[0].ScheduledDayId = x.ScheduledDayId;
    //  this.DHQUser.ScheduledDays[0].IsScheduled = x.IsScheduled;
    })
  }

  EditDHQUser(){

    // console.log(this.DHQUser.DHQUserId, this.DHQUser.Name,this.DHQUser.ScheduledDays);
    this.DHQService.UpdateDHQUser(this.DHQUser.DHQUserId,this.DHQUser.Name,this.DHQUser.IsActive,this.DHQUser.ScheduledDays).subscribe((resp)=>{
      // Store access token, UserId and Role in local storage
      this.Toastr.success('DHQ User Updated Successfully ');
      $("#editDHQUser").modal("hide");
      this.ngOnInit();

      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  export(reportType: number) {
    if(reportType == 1){
      this.exportService.exportExcel(this.DHQList, 'DHQs');
    }
    else if(reportType == 2){
      this.exportService.exportExcel(this.DHQUserPatientsList, 'DHQUser Patients');
    }
    
  }
}
