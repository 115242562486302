import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UpdateVendorModel } from '../Model/Vendors';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(private _http:HttpClient,private router:Router) { }

  CreateNewVendor(data:any):Observable<any>{
    // console.log('data:',data)
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/CreateVendors";
    return this._http.post(FullUrl,data,requestOptions);
  }

  CreateSpecialVendor(data:any):Observable<any>{
    // console.log('data:',data)
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/CreateSpecializedVendors";
    return this._http.post(FullUrl,data,requestOptions);
  }

  EditVendor(updateVendor: UpdateVendorModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/EditVendors";
    return this._http.post(FullUrl,updateVendor,requestOptions);
  }

  EditSpecialVendor(updateVendor: UpdateVendorModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/EditSpecializedVendors";
    return this._http.post(FullUrl,updateVendor,requestOptions);
  }

  getVendors():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetVendors";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getSpecialVendors(departmentid:number):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetVendors?departmentid="+ departmentid;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
}
