import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AppointmentStatusModel } from '../Model/Appointment-StatusModel';
import { GenericServiceService } from '../services/generic-service.service';
import { AppointmentStatusService } from '../services/appointment-status.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-appointment-status',
  templateUrl: './appointment-status.component.html',
  styleUrls: ['./appointment-status.component.css']
})
export class AppointmentStatusComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  appointmentstatusData: Array<AppointmentStatusModel>= new Array();
  isAdmin : boolean = false;
  public  AppointmentStatusName: string;
  selectedAppointmentStatus : AppointmentStatusModel = new AppointmentStatusModel();

  constructor(private appointmentstatusService: AppointmentStatusService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.selectedAppointmentStatus.IsActive = true;
    this.headerTitle.emit("Apppointment Status");
    this.isAdmin = true;
    this.appointmentstatusService.getAppointmentStatusData().subscribe(data=>{
      this.appointmentstatusData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
  }


  selectedAppointmentStatusDetail(appointmentstatus : AppointmentStatusModel){
    this.selectedAppointmentStatus = appointmentstatus;
  }

  createAppointmentStatus(){
    this.appointmentstatusService.CreateNewAppointmentStatus(this.AppointmentStatusName).subscribe((resp)=>{
      this.Toastr.success('Appointment Status Created Successfully ');
      this.ngOnInit();
      this.AppointmentStatusName='';
      },
      (err)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }

  EditAppointmentStatus(){

    this.appointmentstatusService.UpdateAppointmentStatus(this.selectedAppointmentStatus.ReferralStatusId,this.selectedAppointmentStatus.ReferralStatus, this.selectedAppointmentStatus.IsActive).subscribe(resp=>{
      this.Toastr.success('Appointment Status Updated Successfully ');
      $("#editAppointmentStatusModal").modal("hide");
      this.ngOnInit();
      // console.log(resp);
    },err=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(err);
    });

  }

}
