import { Component, OnInit } from '@angular/core';
import { AccountServiceService } from '../account-service.service';
import {  Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMsg: string;
  public Username: string;
  public Password: string;
  constructor(private accountService: AccountServiceService, private router: Router,private Toastr: ToastrService) { }

  ngOnInit() {
    if(localStorage.getItem("access_token") != null){
      this.router.navigate(['/dashboard']);
    }
  }

  loginSubmit(){
    this.errorMsg = null;
    this.Toastr.toastrConfig.progressBar = true;
    this.accountService.login(this.Username,this.Password).subscribe((resp)=>{
      if(resp.userRole !== 'SuperAdmin'){
        this.Toastr.error("You aren't Authorized to Login !!!");
      }
      else{
        this.Toastr.success('Welcome ' + resp.userName);
        // Store access token, UserId and Role in local storage
        localStorage.setItem('access_token',resp.access_token);
        localStorage.setItem('role',resp.userRole);
    
        this.accountService.startup().subscribe(data=>{
        },
        error=>{
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
    
        this.router.navigate(['/dashboard']);
      }
        
    },
    (err)=>{
      if(err.status == 400){
        this.Toastr.error('Invalid Email Address or Password'); 
      }
      else{
        this.Toastr.error("Something went on the server side !!!");
      // console.log(err);
    }
      
    });
  }
}
