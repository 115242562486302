import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DhqdepartmentsService } from '../services/dhqdepartments.service';
import { DHQDeptModel } from '../Model/DHQDepartmentModel';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-dhqdepartments',
  templateUrl: './dhqdepartments.component.html',
  styleUrls: ['./dhqdepartments.component.css']
})
export class DhqdepartmentsComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  DHQDeptData: Array<any>= new Array();
  selectedDHQDepartmentData: DHQDeptModel = new DHQDeptModel();
  Name: string;
  Code: string;
  IsActive: boolean = true;
  isAdmin : boolean = false;
  showselectedDHQDept: boolean = false;
 // selectedGender : Genders = new Genders();

  constructor(private DhqdepartmentsService: DhqdepartmentsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("DHQ Departments");
    this.isAdmin = true;
    this.DhqdepartmentsService.AllDHQDepartments().subscribe(data=>{
      this.DHQDeptData = data;
      // console.log(this.DHQDeptData)
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  selectedDHQDept(data){
    this.selectedDHQDepartmentData = data;
    this.showselectedDHQDept = true;
  } 

  create(){
    this.DhqdepartmentsService.AddNewDHQDepartment(this.Name,this.Code,this.IsActive).subscribe((resp)=>{
      this.Toastr.success('DHQ Department Created Successfully ');
      this.ngOnInit();
      this.Name = '';
      this.Code = '';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  Update(){

    this.DhqdepartmentsService.UpdateDHQDepartment(this.selectedDHQDepartmentData).subscribe(resp=>{
      this.Toastr.success('DHQ Department Updated Successfully ');
      $("#EditDHqDeptModal").modal("hide");
      this.ngOnInit();
      // console.log(resp);
    },err=>{
      // console.log(err);
    });

  } 

}

