import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VendorsService } from '../services/vendors.service';
import { CreateVendorModel, UpdateVendorModel, VendorsModel } from '../Model/Vendors';
import { DistrictsService } from '../services/districts.service';
declare var $: any;

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('contact') contact: ElementRef;
  @ViewChild('address') address: ElementRef;
  @Output() headerTitle = new EventEmitter<string>();
  IsActive: boolean = true;
  isAdmin : boolean = false;
  DHQDeptData: Array<any>= new Array();
  DistrictData: Array<any>= new Array();
  VendorsData: Array<any>= new Array();
  selectedVendorsData: VendorsModel = new VendorsModel();
  showselectedVendor: boolean = false;
  Vendor: string;
  depId:any;
  // ReferralStatusId:any
  statusData:any
  VendorData:VendorsModel = new VendorsModel();
  CreateVendor:CreateVendorModel = new CreateVendorModel();
  public DistrictId: number;
  EditVendor:UpdateVendorModel = new UpdateVendorModel();
  vendorModel : Array<VendorsModel> = new Array();
  // updateVendorModel : UpdateVendorModel = new UpdateVendorModel();
  p:boolean=null
  statusdefModel: Array<any>= new Array();
  selectedvendor: VendorsModel = new VendorsModel();

  constructor(private districtService: DistrictsService, private VendorService:VendorsService, private router: Router,private Toastr: ToastrService) { }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("Simple Vendors");
    this.isAdmin = true;
    // this.DhqdepartmentsService.AllDHQDepartments().subscribe(data=>{
    //   this.DHQDeptData = data;
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    // });
    this.districtService.getAllDistrictData().subscribe(data=>{
      this.DistrictData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
    });

    //get Vendors
    this.vendorModel = [];
    this.VendorService.getVendors().subscribe(data=>{
      this.vendorModel = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  // selectDepartmentForVendors(depId:any){
  //   this.DepartmentId = parseInt(depId)
  // }
  selectDistrictForVendors(Id:any){
    this.DistrictId = parseInt(Id)
  }
  create(){
    // console.log(this.CreateVendor)
    this.VendorService.CreateNewVendor(this.CreateVendor).subscribe((resp)=>{
      
      this.Toastr.success('Vendor Created Successfully ');
      this.searchInput.nativeElement.value = '';
      this.contact.nativeElement.value = '';
      this.address.nativeElement.value = '';
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  UpdateVendor(vendor: any){
    this.EditVendor = vendor
  }
  EditVendors(){
    $("#EditDHqDeptModal").modal("hide");
    // console.log("Before: "+this.selectedDHQ.DHQId);
    this.EditVendor.Id = this.EditVendor.Id;
    this.EditVendor.VendorName = this.EditVendor.VendorName;
    this.EditVendor.DistrictId = this.EditVendor.DistrictId;
    this.EditVendor.VendorContact = this.EditVendor.VendorContact;
    this.EditVendor.Address = this.EditVendor.Address;
    this.EditVendor.IsActive = this.EditVendor.IsActive;
    // console.log(this.EditVendor)
    this.VendorService.EditVendor(this.EditVendor).subscribe(resp=>{
      this.Toastr.success('Vendor Updated Successfully ');
      this.ngOnInit();
      // console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  } 
  // selectDepartmentForUpdatingVendor(data){
  //   this.EditVendor.DepartmentId =  parseInt(data);
  //   console.log(this.EditVendor.DepartmentId)
  // }
  selectDistrictForUpdatingVendor(data){
    this.EditVendor.DistrictId =  parseInt(data);
    // console.log(this.EditVendor.DistrictId)
  }

  selectedVendor(vendor : VendorsModel){
    this.selectedvendor = vendor;
    console.log(this.selectedvendor)
  }
}
