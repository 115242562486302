export class ResponseModel {
    
    ReasonId: number;
    Reason: any;
    DepartmentId: any;
    department:any;
    status:any;
    StatusId:any
    IsActive: boolean;
}

export class UpdateReasonModel{
    ReasonId: number;
    Reason: any;
    departmentid: any;
    statusid:any
    IsActive: boolean;
}

export class CreateReasonModel{
    ReasonId: number;
    Reason: any;
    DepartmentId: any[];
    StatusId:any
    IsActive: boolean;
}