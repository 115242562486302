import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CreateReasonModel, ResponseModel, UpdateReasonModel } from '../Model/ResponseModel';
import { AppointmentStatusService } from '../services/appointment-status.service';
import { DhqdepartmentsService } from '../services/dhqdepartments.service';
import { ResponseService } from '../services/response.service';
declare var $: any;

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.css']
})
export class ResponsesComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('departments') departments: ElementRef;
  @Output() headerTitle = new EventEmitter<string>();
  IsActive: boolean = true;
  isAdmin: boolean = false;
  DHQDeptData: Array<any> = new Array();
  ResponseData: Array<any> = new Array();
  selectedResponseData: ResponseModel = new ResponseModel();
  showselectedResponse: boolean = false;
  Reason: string;
  depId: any;
  // ReferralStatusId:any
  statusData: any
  ReasonData: ResponseModel = new ResponseModel();
  CreateResponse: CreateReasonModel = new CreateReasonModel();
  public StatusId: number;
  public DepartmentId: number;
  EditResponse: UpdateReasonModel = new UpdateReasonModel();
  reasonsModel: Array<ResponseModel> = new Array();
  updateReasonModel: UpdateReasonModel = new UpdateReasonModel();
  p: boolean = null
  statusdefModel: Array<any> = new Array();

  checkedList: any[];
  currentSelected:any[];

  constructor(private DhqdepartmentsService: DhqdepartmentsService, private ResponseService: ResponseService, private status: AppointmentStatusService, private router: Router, private Toastr: ToastrService) {
    this.checkedList = [];this.currentSelected = []
  }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("Responses");
    this.isAdmin = true;
    this.DhqdepartmentsService.AllDHQDepartments().subscribe(data => {
      this.DHQDeptData = data;
      // console.log(this.DHQDeptData)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });


    // this.isAdmin = true;
    //   this.reasonsModel = [];
    //   this.ResponseService.getAllResponses().subscribe(data=>{
    //     this.statusdefModel = data;
    //     var ActiveStatusModel = [];
    //     this.statusdefModel.forEach(function (value) {
    //     if(value.IsActive==true)
    //     {
    //       ActiveStatusModel.push(value);
    //     }
    //  },
    //  this.reasonsModel=ActiveStatusModel

    //  );    console.log(this.reasonsModel)
    // },)
    //get Responses
    // this.ResponseService.getAllResponses().subscribe(data=>{
    //   this.ResponseData = data;
    //   console.log(this.ResponseData)
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    //   console.log(error);
    // });

    //get referralStatus
    this.status.getAppointmentStatusData().subscribe(data => {
      this.statusData = data;
      // console.log(this.statusData)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });

    //get Reasons
    this.reasonsModel = [];
    this.status.getAppointmentStatusReason().subscribe(data => {
      this.reasonsModel = data;
      // console.log(this.reasonsModel)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  selectDepartmentForResponse(depId: any) {
    this.DepartmentId = parseInt(depId)
    // console.log(this.DepartmentId)
  }
  selectReferralStatusForResponse(ReferralStatusId: any) {
    this.StatusId = parseInt(ReferralStatusId)
    // console.log(this.StatusId)
  }

  selectedResponse(data) {
    this.selectedResponseData = data;
    this.showselectedResponse = true;
  }
  create() {
    this.ResponseService.CreateNewResponse(this.CreateResponse).subscribe((resp) => {
      if (resp == "This response already exist") {
        this.Toastr.error(resp);
      } else {
        this.Toastr.success(resp);
        this.ngOnInit();
        
      }
      this.currentSelected = [];
      this.searchInput.nativeElement.value = '';
      this.departments.nativeElement.value = '';
      
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  UpdateResponse(response: any) {
    this.EditResponse = response
    // console.log(this.EditResponse)
  }
  selectStatusForUpdatingReason(data) {
    this.EditResponse.statusid = parseInt(data);
    // console.log(this.EditResponse.statusid)
  }
  selectDepartmentForUpdatingReason(data) {
    this.EditResponse.departmentid = parseInt(data);
    // console.log('array',this.EditResponse.departmentid)
  }

  EditReason() {
    $("#EditDHqDeptModal").modal("hide");
    // console.log("Before: "+this.selectedDHQ.DHQId);
    this.EditResponse.ReasonId = this.EditResponse.ReasonId;
    this.EditResponse.Reason = this.EditResponse.Reason;
    this.EditResponse.statusid = this.EditResponse.statusid;
    this.EditResponse.departmentid = this.EditResponse.departmentid;
    this.EditResponse.IsActive = this.EditResponse.IsActive;

    // console.log(this.EditResponse);
    this.ResponseService.EditResponse(this.EditResponse).subscribe(resp => {
      this.Toastr.success('Response Updated Successfully ');
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  }

  getSelectedValue(status: Boolean,name:String, id: any) {
    // console.log(id)
    if (status) {
      this.checkedList.push(id);
      this.currentSelected.push(name);
    } else {
      var index = this.checkedList.indexOf(id);
      var indexes = this.currentSelected.indexOf(name);
      this.checkedList.splice(indexes, 1);
    }
    // console.log(id, this.checkedList)
    this.CreateResponse.DepartmentId = this.checkedList;
    // this.currentSelected = { checked: status, name: value };
  
  }
 

}
