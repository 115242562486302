export class VendorsModel {
    
    Id: any;
    VendorName: any;
    DistrictId:number;
    District:any;
    VendorContact:any;
    Address:any
    IsActive: boolean;
    VendorUser:{
        UserName : string;
        Password : string;
    }
    
}

export class UpdateVendorModel{
    Id: any;
    VendorName: any;
    DistrictId:number;
    District:any;
    VendorContact:any;
    Address:any
    IsActive: boolean;
}

export class CreateVendorModel{
    Id: any;
    VendorName: any;
    DistrictId:number;
    VendorContact:any;
    Address:any
    isActive: boolean;
}