import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {

 // baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }

  getDashboardData(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetDashboard";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }


  getDashboardPatients(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/GetAllPatients"
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  getDashboardFootwearPatients(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/GetAllFootwearPatients"
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  } 

  getAllRHC(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/GetAllRHCs?districtId="+ data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  GetDHQDepartments():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/DHQ/GetDHQDepartments";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getGenderData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllGenders";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getFootwearDashboardData(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetDashboardFootwear";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  
}
