import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResponseModel, UpdateReasonModel } from '../Model/ResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(private _http:HttpClient,private router:Router) { }

  CreateNewResponse(data:any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/CreateResponse";
    return this._http.post(FullUrl,data,requestOptions);
  }

  EditResponse(updateReason: UpdateReasonModel):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/EditResponse";
    return this._http.post(FullUrl,updateReason,requestOptions);
  }
  getAppointmentStatusReason():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetResponse";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
}
